import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import Data from "../../../../api/data.json";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const ContactMap = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="tp-map-area pt-60 pb-115">
        <div className="container">
          <div className="row">
            <h1 className="text-center">
            We Cover The Entire State Of:
            </h1>
            <div className="col-lg-12 col-md-6">
              <div className="tp-contact-map">
                <Tabs
                  defaultActiveKey={rpdata.id}
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  {rpdata?.dbPrincipal?.location
                    .slice(1, 4)
                    .map((item, index) => {
                      return (
                        <Tab eventKey={ index }  title={item.address}>
                          <iframe
                            title="contact map"
                            src={item.url}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                          ></iframe>
                        </Tab>
                      );
                    })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMap;
